@import "~bootswatch/dist/spacelab/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/spacelab/bootswatch";


.App {
  text-align: center;
}

.App-header {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: green;
}

.App-logo {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 220px;
  height: 161px;
  .App-logo.img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
 }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-title {
  font-weight: 600;
  padding-right:20px;
}

.App-subtitle {
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-home {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  color:darkslategrey;
}

.App-home-carousel-images {
  padding: 5pc, 5pc, 5pc, 5pc;
  
}

.App.footer {
  background-color: white;
  font-style: italic;
  font-weight: bold;
  font-size: small;
}

.nav-link.active {
    color: black !important;
    background: white !important;
}
